<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div class="host-body" v-else>
        <big-header></big-header>
        <div class="host-body-box">
          <video ref="videoElement" class="w-full" controls>
            <source
              src="https://1319812419.vod-qcloud.com/6d467258vodtranssh1319812419/230fbe8a5576678022463811059/v.f100800.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";
import BigHeader from "../../layout/header.vue";
export default {
  mixins: [drawMixin],
  components: {
    BigHeader,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      // setTimeout(() => {
      //   this.loading = false;
      // }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/index.scss";
.host-body-box {
  display: flex;
  flex-direction: column;
}
</style>
